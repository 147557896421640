import axios from "axios";

const api_url =
  process.env.NODE_ENV === "production"
    ? "http://login.emerge.lk:9090/MyPorterAPI/api/"
    : "http://login.emerge.lk:9090/MyPorterAPI/api/";

const API_URL = "http://codebrix-sys.xyz:7031/api/v1/";
// const API_URL = "https://localhost:44391/api/v1/";
// const API_URL = "http://admin.myportersa.com/myporteriis1/api/v1/";

class GeneralDetailsService {
  static async getPrimaryPassenger(email) {
    return axios.get(API_URL + `passenger/passenger-by-email?email=${email}`);
  }

  static async getSecondaryPassenger(email) {
    return axios.get(
      API_URL +
        `passenger/secondary_passangers_by_email?primaryPassengerEmail=${email}`
    );
  }

  // static getSecondaryPassenger() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       // if Promise resolves, return data from the resolve method
  //       resolve(
  //         await axios
  //           .get(api_url + "Passanger/GetSecondryPassangers")
  //           .then((res) => {
  //             return res.data;
  //           })
  //       );
  //     } catch (error) {
  //       // if the promise rejects, return error data from the reject method
  //     }
  //   });
  // }

  static async checkEmailAndMobileConfirmation(data) {
    return await axios.post(API_URL + "system-user/check-validation", data);
  }

  static async getUserProfileDetails() {
    // return await axios.get(api_url + "User/GetAllUserDetails");
  }

  static updateUserProfile(profile) {
    return axios.post(api_url + "Auth/UpdateUserDetails", profile);
  }
}

export default GeneralDetailsService;
