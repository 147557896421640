import axios from "axios";

const api_url =
  process.env.NODE_ENV === "production"
    ? "http://login.emerge.lk:9090"
    : "http://login.emerge.lk:9090";

const API_URL_LOCAL = "http://codebrix-sys.xyz:7031/api/v1/";
// const API_URL = "http://admin.myportersa.com/myporteriis1/api/v1/";
// const API_URL_LOCAL = "https://localhost:44391/api/v1/";

class BookingService {
  // async saveBooking(bookingDetails) {
  //   return await axios.post(
  //     api_url + "/MyPorterAPI/api/Booking/SaveBooking",
  //     bookingDetails
  //   );
  // }

  async saveBooking(bookingDetails) {
    return await axios.post(
      API_URL_LOCAL + "system-user-booking",
      bookingDetails
    );
  }
  async bookingInvoiceSummary(data) {
    return await axios.post(
      API_URL_LOCAL + "system-user-booking/booking-invoice-summary",
      data
    );
  }
  async getBookingDetails(bookingId) {
    return await axios.get(
      api_url + "Booking/GetBookingDeatisl?bookingHeaderID=" + bookingId
    );
  }

  getBookingDetailsByBookingId(bookingId) {
    return axios.get(
      `${api_url}Booking/GetBookingInvoiceDeatisl?bookingHeaderID=${bookingId}`
    );
  }

  getReasonList() {
    return axios.post(`${api_url}/Validation/GetReasons?reasonId=1`);
  }

  async deleteBooking(data) {
    return await axios.delete(API_URL_LOCAL + "system-user-booking", {
      data,
    });
  }

  getBookingHistory(userId) {
    return axios.get(`${API_URL_LOCAL}booking-requests/history/${userId}`);
  }

  getDiscount(data) {
    return axios.post(`${API_URL_LOCAL}coupon/coupon-verify`, data);
  }

  getDistance(sLatitude, sLongitude, eLatitude, eLongitude) {
    return axios.get(
      `${api_url}Booking/GetDistance?sLatitude=${sLatitude}&sLongitude=${sLongitude}&eLatitude=${eLatitude}&eLongitude=${eLongitude}`
    );
  }

  getQrcode(jobId, stage) {
    return axios.get(`${API_URL_LOCAL}qr-code?jobId=${jobId}&stage=${stage}`);
  }
  getCancelReasons() {
    return axios.get(`${API_URL_LOCAL}cancel-reasons`);
  }
  getCurrencyRate(from, to) {
    return axios.get(`${API_URL_LOCAL}currency/getCurrencyRate${from}/${to}`);
  }
  fileUpload(data) {
    return axios.post(`${API_URL_LOCAL}booking-requests/file/upload`, data);
  }
  deleteFile(fileDirectory, fileName) {
    return axios.delete(
      `${API_URL_LOCAL}booking-requests/file/delete/${fileDirectory}/${fileName}`
    );
  }
}

export default new BookingService();
