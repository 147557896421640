import axios from "axios";

const api_url = "http://codebrix-sys.xyz:7031/api/v1/";
// const api_url = "https://localhost:44391/api/v1/";
// const api_url = "http://admin.myportersa.com/myporteriis1/api/v1/";
// process.env.NODE_ENV === "production"
//   ? "http://login.emerge.lk:9090/MyPorterAPI/api/"
//   : "http://login.emerge.lk:9090/MyPorterAPI/api/";

class DropOffLocationService {
  async getDropOffLocations() {
    return await axios.get(`${api_url}airports`);
  }

  async getCalculateDistance(sLatitutes, sLongitudes, eLatitudes, eLongitudes) {
    return await axios.get(
      `${api_url}Booking/GetDistance?sLatitude=${sLatitutes}&sLongitude=${sLongitudes}&eLatitude=${eLatitudes}&eLongitude=${eLongitudes}`
    );
  }
}

export default new DropOffLocationService();
